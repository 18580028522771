import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useContext } from 'react'
import {FaUserAlt} from 'react-icons/fa'
import {HiOutlineMenuAlt3, HiOutlineLogout} from 'react-icons/hi'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Input,
} from '@chakra-ui/react'
import { AuthContext } from '../context/auth';

const Navbar = () => {
  const {currentUser, logout} = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const navigate = useNavigate()

  function Haha(){
    logout();
    navigate("/login")
  }
  return (
    <>
    <div className='hidden md:block font-Roboto'>
      <div className='bg-slate-50'>
        <div className='flex justify-center py-2 font-Roboto font-bold text-xs'>
            <div className='flex items-center'>
              <img className='h-4' src='../../icons/clock.png'/>
              <div className='mx-2'>Ажиллах цаг: Даваа-Баасан 10:30 - 18:30</div>
              <div className='mx-2'>Лхагва 14:00 - 16:30</div>
              <div className='mx-2'>Бямба 11:00 - 15:00</div>
              <div className='mx-2'>Цайны цаг 14:00 - 15:00</div>
              
            </div>
            <div className='mx-12 flex items-center'>
              <img className='h-4' src='../../icons/placeholder.png'/>
              <div className='mx-2'>Хаяг : Хан уул дүүрэг 2-р хороо 19-р хороолол 9-р байрны  1-р орц B1 давхар</div>
            </div>
            <div className='flex items-center'>
              <img className='h-4' src='../../icons/phone.png'/>
              <div className='mx-2'>Утасны дугаар : 9699-9064 9699-9974</div>
            </div>
        </div>
      </div>
      <div className='shadow-xl py-2 '>
        <div className=''>
          <div className='flex justify-between items-center font-Roboto font-bold'>
            <Link to="/" className='flex items-center'>
               <img className='h-16 md:ml-4 lg:ml-20' src='../../icons/logo1.png'/>
               <h1 className='text-blue-600'>АРИГУН КАРГО</h1>
            </Link>
            <div>
            </div>
           <div className='flex'>
            <Link to="/">
            <div>
              Нүүр
            </div>
            </Link>
            <Link to="/help">
            <div className='mx-8'>
              Тусламж
            </div>
            </Link>
            <Link to="/address">
              <div>
                Хаяг холбох
              </div>
            </Link>

            <a className='ml-4 bg-blue-600 text-white rounded-md p-1' href="https://m.facebook.com/groups/2564172970533689/?ref=share&mibextid=S66gvF" target="_blank" rel="noopener noreferrer">Facebook group</a>
           </div>
           <div className='md:mr-4 lg:mr-20 flex items-center'>
           {
            currentUser?
            <Link to="/account">
            <div className='flex items-center cursor-pointer'>
              <img src='../../icons/login.png'/>
              <div className='ml-2'>Миний хуудас</div>
            </div>
            </Link>
            :
            <Link to="/login">
            <div className='flex items-center cursor-pointer'>
              <img src='../../icons/login.png'/>
              <div className='ml-2'>Нэвтрэх</div>
            </div>
            </Link>
           }
           {
            currentUser?
            <Link to="/login">
            <div onClick={Haha} className='flex items-center cursor-pointer bg-blue-600 hover:bg-blue-700 rounded-md ml-4 px-4 py-1 text-white uppercase'>
              <div className='ml-2'>гарах</div>
              <HiOutlineLogout size={20} className="ml-2"/>
            </div>
            </Link>
            :
            <Link to="/register">
            <div className='flex items-center cursor-pointer bg-blue-600 rounded-md ml-4 px-4 py-1 text-white uppercase'>
              <div className='ml-2'>Бүртгүүлэх</div>
              <img className='ml-2' src='../../icons/register.png'/>
            </div>
            </Link>
           }
           </div>
          </div>
        </div>
      </div>
    </div>
    <div className='md:hidden'>
      <div className='bg-white shadow-lg py-4 flex justify-between items-center'>
          <Link to="/" className='flex items-center'>
               <img className='h-10 ml-6' src='../../icons/logo1.png'/>
               <h1 className='text-blue-600 text-xs'>АРИГУН КАРГО</h1>
            </Link>
        <div className='flex items-center'>
        <div className='mr-4 uppercase font-semibold'>
            {
              currentUser?.id?
              <Link to='/account'>
                <div className='bg-gray-200 hover:bg-gray-300 rounded-full p-2'>
                <FaUserAlt size={20} color='gray'/>
                </div>
              </Link>
              :
              <Link to='/login'>
                <div>Нэвтрэх</div>
              </Link>
            }
          </div> 
         <div ref={btnRef} colorScheme='teal' className='mr-6' onClick={onOpen}>
            <HiOutlineMenuAlt3 size={28}/>
         </div>

          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>АРИГУН</DrawerHeader>

              <DrawerBody>
                <div className='grid justify-start ml-8 text-lg'>
                  <Link to='/' className='mt-4'>
                   <div onClick={onClose}> Нүүр</div>
                  </Link>
                  <Link to='/address' className='mt-4' onClick={onClose}>
                    Хаяг холбох
                  </Link>
                  <Link to='/help' className='mt-4' onClick={onClose}>
                    Тусламж
                  </Link>
                  <a className='bg-blue-600 text-white rounded-md mt-4 px-6' href="https://m.facebook.com/groups/2564172970533689/?ref=share&mibextid=S66gvF" target="_blank" rel="noopener noreferrer">Facebook group</a>
                </div>
              </DrawerBody>

              <DrawerFooter>
                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancel
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </div>
    </>
  )
}

export default Navbar