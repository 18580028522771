import { Input, InputGroup, InputLeftElement, Spinner, useToast } from '@chakra-ui/react'
import { useState } from 'react';
import {AiOutlineIdcard,AiOutlinePhone, AiOutlineLock} from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import {registerUri} from "../uri/index"
import {MdOutlineEmail} from "react-icons/md"
import axios from 'axios'

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({ tabaoName: "", email: "", phoneNo: "", password: "", cpassword:""});
  const [err, setErr] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const HandleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    try{
      const res = await axios.post(registerUri, user)
      if(res.status === 200){
        setLoading(false);
        toast({title: 'Амжилттай бүртгүүллээ.',description: "Аригун каргод тавтай морил",status: 'success',duration: 2000,isClosable: true,})
        navigate("/login")
      }
    }catch(err){
      setLoading(false)
      setErr(err.response.data)
    }
  }

  function Haha(){
    toast({title: 'Амжилттай бүртгүүллээ.',description: "Аригун каргод тавтай морил",status: 'success',duration: 1500,isClosable: true,})
  }

  return (
    <div className="">
    <div className="flex justify-center bg-slate-200">
      <div className="my-10 bg-white shadow-lg rounded-md">
        <div className="px-10 py-8 font">
          <h1 className="text-2xl text-center uppercase font-bold">Бүртгүүлэх</h1>
          <p className="text-sm text-center mt-4">САЙН БАЙНА УУ ? АРИГУН КАРГОД ТАВТАЙ МОРИЛНО УУ</p>
           <div className='mt-6'>
            <p className='text-xs mb-2 uppercase'>ХЭРЭГЛЭГЧИЙН НЭР</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineIdcard color='gray' size={25} />}
              />
              <Input type='text' placeholder='ХЭРЭГЛЭГЧИЙН НЭР'
              value={user.tabaoName}
              onChange={ e => setUser({...user, tabaoName:e.target.value})} />
            </InputGroup>
            <p className='mt-2 text-xs mb-2'>И-МЕЙЛ ХАЯГ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<MdOutlineEmail color='gray' size={25} />}
              />
              <Input type='email' placeholder='И-МЕЙЛ ХАЯГ' 
              value={user.email}
              onChange={ e => setUser({...user, email:e.target.value})}/>
            </InputGroup>
            <p className='mt-2 text-xs mb-2'>УТАСНЫ ДУГААР</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlinePhone color='gray' size={25} />}
              />
              <Input type='tel' placeholder='УТАСНЫ ДУГААР'
              value={user.phoneNo}
              onChange={ e => setUser({...user, phoneNo:e.target.value})} />
            </InputGroup>
            <p className='mt-2 text-xs mb-2'>НУУЦ ҮГ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineLock color='gray' size={25} />}
              />
              <Input type='password' placeholder='НУУЦ ҮГ' 
              value={user.password}
              onChange={ e => setUser({...user, password:e.target.value})}/>
            </InputGroup>
            <p className='mt-2 text-xs mb-2'>НУУЦ ҮГ ДАВТАХ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineLock color='gray' size={25} />}
              />
              <Input type='password' placeholder='НУУЦ ҮГ ДАВТАХ'
              value={user.cpassword}
              onChange={ e => setUser({...user, cpassword:e.target.value})} />
            </InputGroup>
            <div className='text-sm text-red-600'>{err}</div>
            <div className='mt-8 '>
              {
                loading?
                <div className='text-center bg-blue-500 rounded-md text-white uppercase py-2 cursor-pointer'><Spinner size="sm"/>Бүртгүүлэх</div>
                :
                <div className='text-center bg-blue-500 rounded-md text-white uppercase py-2 cursor-pointer' onClick={HandleSubmit}>Бүртгүүлэх</div>
              }
              <Link to="/login">
              <p className='text-center text-blue-600 mt-2 uppercase'>Нэвтрэх</p>
              </Link>
            </div>
           </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default Register