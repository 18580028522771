import React, { useContext, useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    ModalCloseButton,
    Input,
    Spinner,
    useToast,
    Checkbox
  } from '@chakra-ui/react'
import { delivery, userdatasirsen, useriduri } from '../../uri';
import { AuthContext } from '../../context/auth';
import { useState } from 'react';
import axios from 'axios';

const AllDelivery = ({callback, prod}) => {
    const toast = useToast();
    const {currentUser, logout} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const[data, setData] = useState([]);
    const[loading, setLoading] = useState(false);
    const[phone, setPhone] = useState()
    const pays = (data.reduce((a,v) =>  a = a + v.pay , 0 ));
    const [checkedValue, setValue] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const dates = new Date();
    dates.setHours(dates.getHours() + 8)

    const handleCallback = () => callback()

    useEffect(() => {
        const fetchData = async ()=>{
          try{
            const resss= await axios.get(userdatasirsen + currentUser?.id)
            setData(resss.data)
          }catch(err){
            console.log(err)
          }
        };
        fetchData();
      },[]);

      const Submit = async ()=>{
        if(!data.length){
          return toast({title: 'Таньд ирсэн бараа байхгүй байна !',description: "Аригун карго -г сонгодогт баярлалаа",status: 'warning',duration: 3000,isClosable: true,})
        }
        if(!phone){
          return toast({title: 'Та нэмэлт утасны дугаараа оруулна уу !',description: "Аригун карго -г сонгодогт баярлалаа",status: 'warning',duration: 3000,isClosable: true,})
        }
        setLoading(true);
        try{
          
          const resa = await axios.get(useriduri+`?userid=${currentUser?.id}`)
          if(resa.data[0].delivery === 2){
            setLoading(false);
            return toast({title: 'Уучлаарай ! Эрхэм харилцагчаа',description: "Таны хүргэлтэнд бүртгүүлсэн барааны хүргэлтийн төлбөр бодогдож баталгаажсан байна. Та эхний баталгаажсан хүргэлтээ хүлээн авсны дараа дараагийн бараагаа хүргэлтэнд бүртгүүлэх боломжтой болно !",status: 'warning',duration: 9000,isClosable: true,})
          }
          let i;
          const res= await axios.post(delivery + currentUser?.id, {helpPhone: phone, date: dates, prod: checkedValue});
          if(res.status === 200){
            setLoading(false);
            handleCallback();
            onClose();
            toast({title: 'Амжилттай Бүртгэгдлээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
          }else{
            setLoading(false);
            toast({title: 'Ямар нэг алдаа гарлааэ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
          }
        }catch(err){
          console.log(err)
          setLoading(false)
          toast({title: 'Алдаа гарлаа',description: "Аригун карго -г сонгодогт баярлалаа",status: 'error',duration: 3000,isClosable: true,})
        }
      };

      // function handleChange({ checked, id }) {
      //   if (checked) {
      //     setValue(prevChecked => [...prevChecked, id]);
      //   } else {
      //     setValue(prevChecked => prevChecked.filter(item => item !== id));
      //   }
      // }

      function handleChange({ checked, id }) {
        // Update the checkedValue state based on individual checkbox change
        if (checked) {
          setValue(prevChecked => [...prevChecked, id]);
        } else {
          setValue(prevChecked => prevChecked.filter(item => item !== id));
        }
      
        // Update the state of "Select All" checkbox based on the number of selected checkboxes
        if (checkedValue.length === prod.length) {
          // If all checkboxes are checked, set "Select All" to checked
          setSelectAll(true);
        } else {
          // If any checkbox is unchecked, set "Select All" to unchecked
          setSelectAll(false);
        }
      }
      

      const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
          // If select all is checked, set all checkboxes to checked
          const allIds = prod.map((item) => item.id);
          setValue(allIds);
        } else {
          // If select all is unchecked, clear all selections
          setValue([]);
        }
      };

  return (
    <>
      <Button onClick={onOpen} className='shadow-xl bg-indigo-600'>Бүх барааг хүргэлтээр авах</Button>

      <Modal isOpen={isOpen} onClose={onClose} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Хүргэлт</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             {/* <h1 className='text-xs text-start'>Та өөр дээрээ ирсэн бүх барааг хүргэлтээр авах гэж байна </h1> */}
             <div className='flex mt-4'>
                <h1 className='flex gap-2'>Хүргэлтээр авах бараа <p className='font-semibold'>{data.length}</p></h1>
             </div>
             <Input className='mt-4 mb-2' placeholder='Нэмэлт утасны дугаар' onChange={e=> setPhone(e.target.value)}/>
             <Checkbox className="mt-2" isChecked={selectAll} onChange={handleSelectAllChange}>Бүгдийг</Checkbox>
             <div>
              {
                prod.map((it, index) => {
                  return(
                    <div className='flex justify-between items-center border rounded p-1 mt-1' key={index}>
                      <div className='flex items-center gap-2'>
                        <Checkbox isChecked={checkedValue.includes(it.id)} onChange={(e) => handleChange({ checked:e.target.checked, id:it.id })}/>
                        <h1 className='text-sm font-bold'>{it.trackCode}</h1>
                      </div>
                      <h1 className='text-sm'>{it.note}</h1>
                    </div>
                  )
                })
              }
             </div>
             
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Буцах
            </Button>
            {
                loading?
                <Button colorScheme='green'><Spinner size='sm' className="mr-2"/>Хүргэлтэнд бүртгүүлэх</Button>
                :
                <Button onClick={Submit} colorScheme='green'>Хүргэлтэнд бүртгүүлэх</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AllDelivery