import axios from 'axios';
import React, { useEffect, useState } from 'react'
import AddressComp from '../components/Card/address'
import { addressall } from '../uri';

const Address = () => {
  const[data, setData] = useState([]);

useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async ()=>{
    try{
      const res= await axios.get(addressall)
      console.log(res.data)
      setData(res.data)
    }catch(err){
      console.log(err)
    }
  };


  return (
    <>
    <h1 className='font-Roboto text-center text-2xl mt-8'>Хаяг холбох</h1>
    <div className='my-6 flex justify-center'>
        {data.map((data, index) => (
          <AddressComp
          key={index}
          data={data}
          />
          ))}
    </div>
    </>
  )
}

export default Address 

