import React from 'react'
import {IoMdCopy} from 'react-icons/io'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useToast } from '@chakra-ui/react'

const AddressComp = (data) => {
    const toast = useToast()
    
    function CopyAlert(){
        toast({title: 'Хууллаа',status: 'success',duration: 3000,isClosable: true,})
    }
  return (
    <div className='mx-2'>
        <div className='font-Roboto ring-1 rounded-lg ring-gray-400 px-8 py-10 shadow-xl hover:ring-2 hover:ring-blue-600'>
            <h1 className='uppercase'>{data.data.title}</h1>
            <div className='mt-4'>
                <h1>收件人 (Хүлээн авагч)</h1>
                <div className='flex items-center mt-1'>
                    <AiOutlineCheckCircle size={18} className='mr-2'/>
                    <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                       <h1>{data.data.selfphone}</h1>
                    </div>
                    <CopyToClipboard text={data.data.selfphone}>
                        <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                            <IoMdCopy size={23} />
                            <h1 className=''>copy</h1>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

            <div className='mt-4'>
                <h1>电话 (Утас)</h1>
                <div className='flex items-center mt-1'>
                    <AiOutlineCheckCircle size={18} className='mr-2'/>
                    <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                       <h1>{data.data.phone}</h1>
                    </div>
                    <CopyToClipboard text={data.data.phone}>
                        <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                            <IoMdCopy size={23} />
                            <h1 className=''>copy</h1>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

            <div className='mt-4'>
                <h1>所在地区 (Бүс нутаг)</h1>
                <div className='flex items-center mt-1'>
                    <AiOutlineCheckCircle size={18} className='mr-2'/>
                    <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                       <h1>{data.data.zone}</h1>
                    </div>
                    <CopyToClipboard text={data.data.zone}>
                        <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                            <IoMdCopy size={23} />
                            <h1 className=''>copy</h1>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

            <div className='mt-4'>
                <h1>街道地址 (Хаяг)</h1>
                <div className='flex items-center mt-1'>
                    <AiOutlineCheckCircle size={18} className='mr-2'/>
                    <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                       <h1>{data.data.address}</h1>
                    </div>
                    <CopyToClipboard text={data.data.address}>
                        <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                            <IoMdCopy size={23} />
                            <h1 className=''>copy</h1>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
            <div className='mt-4'>
                <h1>邮编 (Зип код)</h1>
                <div className='flex items-center mt-1'>
                    <AiOutlineCheckCircle size={18} className='mr-2'/>
                    <div className='w-60 bg-gray-200 rounded-md py-1 px-2'>
                       <h1>{data.data.zipcode}</h1>
                    </div>
                    <CopyToClipboard text={data.data.zipcode}>
                        <div onClick={CopyAlert} className='flex items-center cursor-pointer'>
                            <IoMdCopy size={23} />
                            <h1 className=''>copy</h1>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

        </div>
    </div>
  )
}

export default AddressComp