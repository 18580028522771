import moment from 'moment';
import React from 'react'

const AlertComp = ({track}) => {
  const datePicker =  moment(track.date).format("L");

  return (
    <div className='bg-gray-100 rounded-md px-4 py-2 font-Roboto mt-2 hover:ring-2 hover:ring-blue-600'>
        <div className='flex items-center justify-between'>
            <h1 className='text-xl font-bold'>{track.title}</h1>
            <div>{datePicker}</div>
        </div>
        <div className='mt-1'>
            <p className='text-sm'>
            {track.message}
            </p>
        </div>
    </div>
  )
}

export default AlertComp