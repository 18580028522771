import { Input, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import { useContext, useState } from 'react';
import {AiOutlineIdcard, AiOutlineLock} from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import {AuthContext} from "../context/auth"


const Login = () => {
  const [user, setUser] = useState({ email: "", password: ""});
  const [err, setErr] = useState(null)
  const navigate = useNavigate();
  const [loading, setLoading]= useState(false);

  const { login } = useContext(AuthContext);

  const HandleSubmit = async e => {
    setLoading(true);
    e.preventDefault()
    try{
     await login(user)
      setLoading(false);
      navigate("/account")
    }catch(err){
      setLoading(false);
      setErr(err.response.data)
    }
  }

  return (
   <div className="">
    <div className="flex justify-center bg-slate-200">
      <div className="my-10 bg-white shadow-lg rounded-md">
        <div className="px-10 py-8 font">
          <h1 className="text-2xl text-center uppercase font-bold">Нэвтрэх</h1>
          <p className="text-sm text-center mt-4">САЙН БАЙНА УУ ? АРИГУН КАРГОД ТАВТАЙ МОРИЛНО УУ</p>
           <div className='mt-6'>
            <p className='text-xs mb-2'>И-МЕЙЛ ХАЯГ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineIdcard color='gray' size={25} />}
              />
              <Input type='email' placeholder='И-МЕЙЛ ХАЯГ' 
               value={user.email}
               onChange={ e => setUser({...user, email:e.target.value})}/>
            </InputGroup>
            <p className='mt-2 text-xs mb-2'>НУУЦ ҮГ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineLock color='gray' size={25} />}
              />
              <Input type='password' placeholder='НУУЦ ҮГ' 
               value={user.password}
               onChange={ e => setUser({...user, password:e.target.value})}/>
            </InputGroup>
              <Link to="/forget">
              <p className='text-end text-blue-600 mt-2'>Нууц үгээ мартсан уу ?</p>
              </Link>
              <div className='text-xs text-red-600'>{err}</div>
            <div className='mt-8 '>
              {
                loading?
                <div className='text-center bg-blue-500 rounded-md text-white uppercase py-2 cursor-pointer'> <Spinner size="sm"/>Нэвтрэх</div>
                :
                <div className='text-center bg-blue-500 rounded-md text-white uppercase py-2 cursor-pointer' onClick={HandleSubmit}>Нэвтрэх</div>
              }
              <Link to="/register">
              <p className='text-center text-blue-600 mt-2 uppercase'>Бүртгүүлэх</p>
              </Link>
            </div>
           </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default Login