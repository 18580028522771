import React from 'react'
import {Link } from 'react-router-dom'

const welcome = () => {
  return (
    <div className='mt-24 bg-slate-100'>
        <div className='flex flex-wrap justify-center py-20'>
            <div>
                <h1 className='text-center'>WELCOME TO ARIGUN CARGO</h1>
                <h1 className='font-bold xs:text-lg md:text-3xl text-center'>АРИГУН КАРГО -Д ТАВТАЙ МОРИЛНО УУ</h1>
                <div className='flex justify-center flex-wrap mt-10'>
                    <div className='xs:w-full xs:mx-10 md:w-96 cursor-pointer bg-white shadow-lg hover:ring-2 hover:ring-color-blue-600 transition ease-linear'>
                        <div className='flex justify-center mt-8'>
                        <img className='h-28' src='../../icons/1.png'/>
                        </div>
                        <div className='mx-14'>
                        <div className='text-center mt-4 font-bold'>Трак кодоо бүртгүүлэх</div>
                        <p className=' text-center mt-2'>Та трак кодоо бүртгүүлэснээр ачаа бараагаа хаана явааг мэдэх юм.</p>
                        <Link to="/account">
                          <div className='text-center bg-blue-600 text-white rounded-sm mt-6 py-2 hover:ring-2 hover:bg-blue-500 transition ease-linear'>Бүртгүүлэх</div>
                        </Link>
                        </div>
                        <div className='flex justify-center mt-4 pb-10'>
                            <img src='../../icons/tseg.png'/>
                        </div>
                    </div>
                    <div className='xs:w-full xs:mx-10 md:w-96 xs:mt-4 md:mt-0 md:mx-4 cursor-pointer bg-white shadow-lg hover:ring-2 hover:ring-color-blue-600 transition ease-linear'>
                        <div className='flex justify-center mt-8'>
                        <img className='h-28' src='../../icons/2.png'/>
                        </div>
                        <div className='mx-14'>
                          <div className='text-center mt-4 font-bold'>Хаяг холбох</div>
                        <p className=' text-center mt-2'>Манай каргоны үндсэн хаягуудыг холбох</p>
                        <Link to="/address">
                        <div className='text-center bg-blue-600 text-white rounded-sm mt-6 py-2 hover:ring-2 hover:bg-blue-500 transition ease-linear'>Холбох</div>
                        </Link>
                        </div>
                        <div className='flex justify-center mt-4 pb-10'>
                            <img src='../../icons/tseg.png'/>
                        </div>
                    </div>
                    <div className=' xs:w-full xs:mx-10 md:w-96  xs:mt-4 md:mt-0 cursor-pointer bg-white shadow-lg hover:ring-2 hover:ring-color-blue-600 transition ease-linear'>
                        <div className='flex justify-center mt-8'>
                        <img className='h-28' src='../../icons/3.png'/>
                        </div>
                        <div className='mx-14'>
                        <div className='text-center mt-4 font-bold'>Тусламж</div>
                        <p className=' text-center mt-2'>Түгээмэл асуулт хариултууд болон видьео хичээл</p>
                        <Link to="/help">
                        <div className='text-center bg-blue-600 text-white rounded-sm mt-6 py-2 hover:ring-2 hover:bg-blue-500 transition ease-linear'>Үзэх</div>
                        </Link>
                        </div>
                        <div className='flex justify-center mt-4 pb-10'>
                            <img src='../../icons/tseg.png'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default welcome