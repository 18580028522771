import { Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { useContext, useState } from 'react';
import {AiOutlineIdcard, AiOutlineLock} from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import {AuthContext} from "../context/auth"
import axios from 'axios';
import { forgetUri } from '../uri';


const Forget = () => {
  const [user, setUser] = useState({ email: ""});
  const [err, setErr] = useState(null)
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const toast = useToast()

  const { login } = useContext(AuthContext);

  const HandleSubmit = async e => {
    setLoad(true)
    e.preventDefault()
    try{
     const res = await axios.post(forgetUri, {email: user.email})
     console.log(res.status)
     if(res.status === 200){
        setLoad(false)
        navigate("/login")
        toast({title: 'Баталгаажуулах линк илгээлээ',description: "Та имейл хаягаа шалгана уу",status: 'success',duration: 5000,isClosable: true,})
     }else{
      setLoad(false)
      toast({title: 'Алдаа',description: "Ямар нэг алдаа гарлаа та админд хандана уу",status: 'error',duration: 3000,isClosable: true,})
     }
    }catch(err){
      setLoad(false)
      setErr(err.response.data)
    }
  }

  return (
   <div className="">
    <div className="flex justify-center bg-slate-200">
      <div className="my-10 bg-white shadow-lg rounded-md">
        <div className="px-10 py-8 font">
          <h1 className="text-2xl text-center uppercase font-bold">Нууц үг мартсан Уу</h1>
          <p className="text-sm text-center mt-4">Та өөрийн имейл хаягаар нууц үгээ сэргээж авна уу</p>
           <div className='mt-6'>
            <p className='text-xs mb-2'>И-МЕЙЛ ХАЯГ</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineIdcard color='gray' size={25} />}
              />
              <Input type='email' placeholder='И-мейл хаяг' 
               value={user.email}
               onChange={ e => setUser({...user, email:e.target.value})}/>
            </InputGroup>
              <div className='text-xs text-red-600'>{err}</div>
            <div className='mt-8 '>
              {
                load?
                <div className='text-center bg-blue-500 hover:bg-blue-600 rounded-md text-white uppercase py-2 cursor-pointer'
                >Илгээж байна...</div>
                :
                <div className='text-center bg-blue-500 hover:bg-blue-600 rounded-md text-white uppercase py-2 cursor-pointer'
                onClick={HandleSubmit}>Нууц үг илгээх</div>
              }
              <Link to="/login">
              <p className='text-center text-blue-600 mt-2 uppercase'>Нэвтрэх</p>
              </Link>
            </div>
           </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default Forget