import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import SidebarAccount from './Acc/sidebaracc';
import { AuthContext } from '../context/auth';

const Account = () => {
  const {currentUser, logout} = useContext(AuthContext);

  if(currentUser){
    return (
      <div className='bg-slate-200 font-Roboto'>
        <div className='xs:mx-4 xl:container py-8'>
          <div className='grid xs:grid-cols-1 xl:grid-cols-4 gap-3'>
  
            <div className='relative'>
              <div className='grid col-span-1'>
                <SidebarAccount/>
              </div>
            </div>
  
            <div className='grid col-span-3 w-full overflow-x-auto'>
              <div className=''>
                <Outlet/>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    )
  }else{
    return <Navigate to='/login'/>
  }
}

export default Account