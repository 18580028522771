import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import {BsBookmarkHeart} from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { useriduri } from '../../uri';
import { AuthContext } from '../../context/auth';
import SystemExit from '../../components/Modal/systemexit';

const SidebarAccount = () => {
  const {currentUser} = useContext(AuthContext);
  const path = useLocation();
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoad(true)
    try{
        const res = await axios.get(useriduri+`?userid=${currentUser?.id}`)
        setUser(res.data[0]);
        setLoad(false)
    }catch(err){
        setLoad(false)
        console.log(err);
    }
  }


  return (
    <div className='bg-white rounded py-8 w-full'>
        <div className='flex justify-center flex-col'>
            <img className='xs:h-12 md:h-28 rounded-full' src='./images/pro.svg'/>
            <>
              {
                load?
                <p className='text-sm text-black/70 mt-2 text-center'>Уншиж байна ...</p>
                :
               <>
                <p className='text-sm text-black/70 mt-2 text-center'>{user.email}</p>
                <div className='flex items-center justify-center mt-1'>
                    <BsBookmarkHeart color='#663CB8'/>
                    <h1 className='text-xl text-center uppercase ml-2 font-semibold'>{user.tabaoName}</h1>
                </div>
               </>
              }
            </>
            <div className='mt-4 flex flex-col'>
                <Link to='/account' className={path.pathname === '/account' ? 'text-lg font-semibold px-4 py-1 border-l-4 border-indigo-600' : 'text-lg px-4 py-1 '}>Хувийн мэдээлэл</Link>
                <Link to='/account/orders' className={path.pathname === '/account/orders' ? 'text-lg font-semibold px-4 py-1 border-l-4 border-indigo-600' : 'text-lg px-4 py-1'}>Миний захиалгууд</Link>
                <SystemExit/>
            </div>
        </div>
    </div>
  )
}

export default SidebarAccount