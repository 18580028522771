import React, { useContext, useEffect, useState } from 'react'
import {BiUser} from 'react-icons/bi'
import {HiOutlineHome} from 'react-icons/hi'
import { Link } from 'react-router-dom'
import PersonalComp from './Comp/personal';
import AddressComp from './Comp/address';
import TrackReqModal from '../../components/Modal/trackreq';
import axios from 'axios';
import { AuthContext } from '../../context/auth';
import { useriduri } from '../../uri';
import { Spinner } from '@chakra-ui/react';

const DashboardAccount = () => {
  const [path, setPath] = useState(1);
  const {currentUser} = useContext(AuthContext);
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoad(true)
    try{
        const res = await axios.get(useriduri+`?userid=${currentUser?.id}`)
        setUser(res.data[0]);
        setLoad(false)
    }catch(err){
        console.log(err);
        setLoad(false)
    }
  }
  
  const callback =()=> {
    fetchData();
  }

  return (
    <div>
      <div className='pb-2 font-Roboto'>
        <div className='flex justify-start items-center gap-2 flex-wrap'>
          <div onClick={()=> setPath(1)} 
            className={path === 1 ? 'flex justify-center cursor-pointer items-center text-white bg-indigo-600 rounded py-2 px-6': 
            'flex justify-center cursor-pointer items-center text-black bg-white rounded py-2 px-6 text-opacity-70 hover:bg-indigo-600 hover:text-white hover:transition'
            }>
            <BiUser size={32}/>
            <div className='px-2 border-l border-white ml-2'>
              <h1 className='font-semibold xs:text-sm xl:text-lg'>Хувийн мэдээлэл</h1>
              {
                load?
                <Spinner size='sm'/>
                :
                <p className='text-sm hidden xl:block'>{user.email}</p>
              }
            </div>
          </div>
          <div onClick={()=> setPath(2)} 
            className={path === 2 ? 'flex justify-center cursor-pointer items-center text-white bg-indigo-600 rounded py-2 px-6': 
            'flex justify-center cursor-pointer items-center text-black bg-white rounded py-2 px-6 text-opacity-70 hover:bg-indigo-600 hover:text-white  hover:transition'
            }>
            <HiOutlineHome size={32}/>
            <div className='px-2 border-l border-white ml-2'>
              <h1 className='font-semibold  xs:text-sm xl:text-lg'>Гэрийн хаяг</h1>
              {
                load?
                <Spinner size='sm'/>
                :
                <p className='text-sm hidden xl:block'>Ажил гэрийн хаягууд</p>
              }
            </div>
          </div>
          <TrackReqModal/>
        </div>
      </div>

      <div className='bg-white rounded p-4'>
        {
          path === 1 &&
          <PersonalComp user={user} callback={callback}/>
        }
        {
          path === 2 &&
          <AddressComp user={user} callback={callback}/>
        }
      </div>
    </div>
  )
}

export default DashboardAccount
