import { Button, Input, Textarea, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { userAddress, userupdate } from '../../../uri';

const AddressComp = ({user, callback}) => {
  const [duureg, setDuureg] = useState(user.duureg);
  const [khoroo, setKhoroo] = useState(user.khoroo);
  const [toot, setToot] = useState(user.toot);
  const [address, setAddress] = useState(user.address);
  const [load, setLoad] = useState(false)
  const toast = useToast();

  useEffect(() => {
    setDuureg(user.duureg);
    setKhoroo(user.khoroo);
    setToot(user.toot);
    setAddress(user.address);
  },[user]);

  const handleCallback = () => callback()

  const Submit = async () => {
    setLoad(true)
    try{
      const res= await axios.post(userAddress + user.id, {address: address, duureg: duureg, khoroo: khoroo, toot: toot})
      handleCallback();
      setLoad(false)
      toast({title: 'Хаяг нэмлээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
    }catch(err){
      setLoad(false)
      console.log(err)
    }
  };

  return (
    <div>
      <div className='flex gap-2 flex-wrap'>
        <div className=''>
          <p>Дүүрэг/Аймаг</p>
          <Input className='mt-2' placeholder='' value={duureg} onChange={(e)=> setDuureg(e.target.value)} />
        </div>
        <div className=''>
          <p className=''>Хороо/Баг</p>
          <Input className='mt-2' placeholder='' value={khoroo} onChange={(e)=> setKhoroo(e.target.value)} />
        </div>
        <div className=''>
          <p>Тоот/гудамж</p>
          <Input className='mt-2' placeholder='' value={toot} onChange={(e)=> setToot(e.target.value)} />
        </div>
      </div>
      <div className='flex w-full gap-2 mt-2'>
        <div className='w-full'>
          <p>Дэлгэрэнгүй хаяг хүмүүсийн мэддэгээр</p>
          <Textarea className='mt-2' placeholder='' value={address} onChange={(e)=> setAddress(e.target.value)} />
        </div>
      </div>
      <div className='flex justify-end mt-2'>
        {
          load?
          <Button className='shadow-xl animate-bounce'>Хадгалж байна...</Button>
          :
          <Button className='shadow-xl' onClick={Submit}>Хаяг хадгалах</Button>
        }
      </div>
    </div>
  )

}

export default AddressComp