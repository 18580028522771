import React, { useContext, useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Spinner
  } from '@chakra-ui/react'
import { AuthContext } from '../../context/auth';
import { Link, useNavigate } from 'react-router-dom';

const SystemExit = () => {
    const {logout} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);

    function Haha(){
        setLoad(true)
        logout();
        navigate("/login")
        setLoad(false)
      }

  return (
    <>
        <div className='text-lg px-4 cursor-pointer' onClick={onOpen}>
            Системээс гарах
        </div>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Системээс гарахдаа итгэлтэй байна уу</ModalHeader>
            <ModalCloseButton />

            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={Haha}>
                    Гарах
                    {
                        load &&
                        <Spinner/>
                    }
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default SystemExit