import React, { useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Spinner
  } from '@chakra-ui/react'
import { useState } from 'react'
import { allalert, useraddawsan } from '../../uri'
import axios from 'axios'

const AwsanModal = ({data, callback}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [id, setId] = useState()
    const [load, setLoad] = useState(false);

    const handleCallback = () => callback()
    useEffect(() => {
        setId(data)
    },[data]);

    const fetchData = async ()=>{
        setLoad(true)
        try{
            const res = await axios.post(useraddawsan, {type:4, id: id})
            handleCallback();
            onClose();
            setLoad(false)
        }catch(err){
            console.log(err)
            setLoad(false)
        }
    };

  return (
    <>
    <Button className='ml-2 shadow-md' size='sm' onClick={onOpen}>
        Авсан
    </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Анхааралтай уншина уу</ModalHeader>
            <ModalCloseButton />
            
            <ModalBody>
                <h1 className=' font-semibold font-Roboto'>
                    Та энэхүү төлөвийг баталгаажуулснаар таны бараа эзэндээ очсон төлөвт шилжинэ.
                </h1>
                <h1 className=' font-semibold font-Roboto mt-2'>
                    Та энэ барааг хүлээж авсан бол баталгаажуулна уу !
                </h1>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='gray' mr={3} onClick={onClose}>
                    Болих
                </Button>
                <Button colorScheme='blue' mr={3} onClick={fetchData}>
                    {
                        load&&
                        <Spinner className='mr-2'/>
                    }
                    Баталгаажуулах
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default AwsanModal