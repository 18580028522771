import React, { useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure
  } from '@chakra-ui/react'
import { useState } from 'react'
import { allalert } from '../../uri'
import axios from 'axios'
import AlertComp from '../Card/alert'

const Alert = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const[data, setData] = useState([])

    useEffect(() => {
        const fetchData = async ()=>{
          try{
            const res= await axios.get(allalert)
            setData(res.data)
            if(res.data.length > 0){
                onOpen()
            }
          }catch(err){
            console.log(err)
          }
        };
        fetchData();
      },[]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Мэдэгдэл</ModalHeader>
          <ModalCloseButton />
          
          <ModalBody>
            {data.map((track, index) => (
                <AlertComp
                key={index}
                track={track}
                />
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Ойлголоо
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default Alert