import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    useToast,
    Spinner
  } from '@chakra-ui/react'
import axios from 'axios';
import { useContext, useState } from 'react'
import { BsTruck } from 'react-icons/bs'
import { addtrack, ezenguibaraaadd, searchtrack } from '../../uri';
import { AuthContext } from '../../context/auth';

const TrackReqModal= ({callback}) => {
  const {currentUser} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [trackCode, setTrack] = useState(null);
    const [note, setNote] = useState(null);
    const toast = useToast();
    const [loading, setLoading]= useState(false);

    const date = new Date();
    // date.setHours(date.getHours() + 8)

    const handleCallback = () => callback();

  const HandleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
   if(trackCode == null){
    setLoading(false)
    return toast({title: 'Алдаа',description: "Та трак кодоо оруулна уу !",status: 'error',duration: 3000,isClosable: true,})
   }
   if(note == null){
    setLoading(false)
    return toast({title: 'Алдаа',description: "Та тэмдэглэлээ оруулна уу !",status: 'error',duration: 3000,isClosable: true,})
   }
   try{
    const ress = await axios.post(searchtrack, {track: trackCode});
    if(ress.data.length == 0){
        const res = await axios.post(addtrack, {trackCode: trackCode, note: note, date: date, type:1, userId:currentUser?.id});
        if(res.status === 200){
          setLoading(false);
          toast({title: 'Таны трак кодыг бүртгэлээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
          setNote()
          setTrack()
          onClose()
          handleCallback();
        }
    } else{
      if(ress.data[0].type == 11){
        const resdata = await axios.post(ezenguibaraaadd + ress.data[0].id, {userId: currentUser?.id, date: date, type:3, note: note})
        console.log(resdata, "ahha")
        if(resdata.status == 200){
          toast({title: 'Таны трак кодыг бүртгэлээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
        }
        handleCallback();
        setNote();
        setTrack();
        setLoading(false);
        onClose();
      }else{
        const resdatas = await axios.post(ezenguibaraaadd + ress.data[0].id, {userId: currentUser?.id, date: date, type:ress.data[0].type,note: note})
        console.log(resdatas, "ahha")
        if(resdatas.status == 200){
          setNote()
          setTrack()
          onClose()
          handleCallback();
          toast({title: 'Таны трак кодыг бүртгэлээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
        }
        setLoading(false);
      }
    }
  
  }catch(err){
    setLoading(false)
    console.log(err.response.data)
  }
  }

    return (
      <>
        <div onClick={onOpen}
            className='flex justify-center cursor-pointer items-center text-black bg-white rounded py-2 px-6 text-opacity-70 hover:bg-indigo-600 hover:text-white  hover:transition'>
            <BsTruck size={32}/>
            <div className='px-2 border-l border-white ml-2'>
              <h1 className='font-semibold  xs:text-sm xl:text-lg'>Трак бүртгүүлэх</h1>
              <p className='text-sm hidden xl:block'>Барааны кодоо бүртгүүлэх</p>
            </div>
        </div>
  
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Трак код бүртгүүлэх</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <Input value={trackCode} onChange={(e) => setTrack(e.target.value)} placeholder='Трак код оруулах'/>
                <Input value={note} onChange={(e) => setNote(e.target.value)} placeholder='Тэмдэглэл (Өмд, цамц гэх мэтээр бараагаа тэмдэглэ)' className='mt-2'/>
              </div>
            </ModalBody>
  
            <ModalFooter>
                <Button onClick={onClose} variant='ghost'>Болих</Button>
                {
                  loading?
                  <Button className=' xs:w-full lg:w-2/4 ml-2' colorScheme='green'> <Spinner size="sm"/>Бүртгүүлэх</Button>
                  :
                  <Button className=' xs:w-full lg:w-2/4 ml-2' colorScheme='green' onClick={HandleSubmit}>Бүртгүүлэх</Button>
                }
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default TrackReqModal