import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
  } from '@chakra-ui/react'

const Help = () => {
  return (
    <div className='py-20 xs:mx-2 md:mx-40'>
        <div>
          <div className='uppercase font-bold text-2xl ml-4'>ТҮГЭЭМЭЛ АСУУЛТ ХАРИУЛТ</div>
                <div className='mt-4 mx-4'>
                <Accordion allowToggle>
                    <AccordionItem>
                        <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            1кг ачаа хэд вэ ?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} mx={4}>
                            <p>0-100гр 1000₮</p>
                            <p>100-1кг 2500₮</p>
                            <p>1-2кг 5000₮</p>
                            <p>Хайрцагтай ачаа эхний кг 3000₮ </p>
                            <p>Жин багатай овортой ачаа mk3-р бодогдоно</p>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                            мк3 ачааны үнэ ?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          Бодогдох үнэ нь 650 юань
                        </AccordionPanel>
                    </AccordionItem>
                    </Accordion>
            </div>
        </div>

        <div className='uppercase font-bold text-2xl ml-4 mt-8'>Тусламж</div>
        <div className='mt-4'>
            <div className='flex items-center ml-4'>
               <span className=''>1. www.arigun.mn сайтад бүртгэлээ үүсгэж бараагаа шалгаад явна. Хэрэглэхэд амар шүү!! Доорхи линкэнд бүртгүүлэх заавар код оруулах заавар гээд бүгд байгаа👇🏻👇🏻  <a target="_blank" className='mx-4 text-blue-600' href='https://www.facebook.com/groups/2564172970533689/permalink/3769700236647617/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>2. хүргэлт бүртгүүлэх заавар👇🏻<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3962655347352104/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>Taobao хаяг холбох заавар👇🏻<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3922791258005180/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>4. Pindoudou хаяг холбох заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3868148823469424/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>5. Poizon хаяг холбох заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3904564623161177/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>6. Дэлгэцээ 2 товшоод л шууд орчуулга хийх заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3946418952309077/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>7. Буруу код бүртгүүлээд байгаа хүмүүс зориулсан заавар. Pindoudou <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3950971798520459/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>8. Taobao линк захиалга өгөх хүмүүст өгөх заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3653948778222764/?mibextid=W9rl1R4172970533689/permalink/3653948778222764/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>9. Газрын зурагны доор хүлээж авсан нэр өөр байх нь асуудал биш шүү!<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3194452444172402/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>10. Таобао дэлгүүр гаргаагүй байрааны буцаалт<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2748768128740838/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>11. барааны код харах, хаана явааг харах, захиалсан бүх бараагаа шалгах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3225468421070804/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>12. Пингдоу буцаалт<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3224631551154491/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>13. Дэлгүүрээсээ гарсан Барааг буцаах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3216784858605827/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>14. Шилэн эдлэл захих үед бичэх үг<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2749186578698993/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>15. Эрээн хот орсон ороогүйг шалгах<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3076813382602976/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>16. Картаар захиалга хийдэг хүмүүст төлбөр илүү татсан үед<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3169629339988046/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>17. Барааны доорхи үгээр ижил бараа хайх<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2726387077645610/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>18.  Кодоороо ямар бараа байгааг бас хаана явж байгахг харах апп ашиглах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2901626800121636/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>19. Шил толь хагарах барааны захиалга авахгүй.<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3163153693968944/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>20. Машины эд ангийн нэршил<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2599284630355856/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>21. Google translate ашиглах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2726314247652893/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>22. барааны кодоо харж сурах<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2922239688060347/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>23. Карт холбох дараалал<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2759434317674219/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>24. Од дээр дарж хадгалсан бараа болон танй нийт үзсэн барааны түүх харах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2655763834707935/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>25. Хувцасны хэмжээнүүд<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2730494927234825/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>26. Таобао Нууц үг үүсгэх заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/2915797535371229/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>27. Таобао Удсан барааг буцаах<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3344408522510126/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>28. Нэр бараа дутуу ирэх үед<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3388476234770021/'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>29. Бараа нь эзэнгүйд ирээд байгаа тохиолдол<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3643177905966518/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>30. Хүргэгдэхгүй ачааг хурдлуулах таобао<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3643375839280058/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>31. Пиндоудоу дэлгүүрт гомдол гаргах заавар<a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3868148823469424/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
            <div className='flex items-center ml-4'>
               <span className=''>32. Хэрэгтэй хятад үгснүүд <a target="_blank" className='mx-4 text-blue-500' href='https://www.facebook.com/groups/2564172970533689/permalink/3975093932774912/?mibextid=W9rl1R'>хичээл үзэх</a></span>
            </div>
           

        </div>
    </div>
  )
}

export default Help