
import {Route, Routes} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Help from './pages/Help';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Address from './pages/Address'
import Account from './pages/Account';
import Alert from './components/Modal/alert';
import Forget from './pages/Forget';
import DashboardAccount from './pages/Acc/dashboard';
import OrdersAccount from './pages/Acc/orders';
import Activate from './pages/Activate';

function App() {
  return (
    <>
      <ToastContainer/>
      <Navbar/>
      <Alert/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/help' element={<Help/>}/>
        <Route path='/address' element={<Address/>}/>
        <Route path='/activate/:token' element={<Activate/>}/>
        {/* <Route path='/account' element={<Account/>}/> */}
        <Route path='/forget' element={<Forget/>}/>

        <Route path="/account" element={<Account />}>
          <Route index element={<DashboardAccount/>}/>
          <Route path="orders" element={<OrdersAccount/>}/>
        </Route>
        
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
