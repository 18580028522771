import { createContext, useEffect, useState } from "react";
import axios from "axios"
import { loginUri, logoutUri } from "../uri";
import { useToast } from "@chakra-ui/react";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) =>{
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("AfgHJSHGDAFAgfgaf")) || null)

    const toast = useToast();

    const login = async(user)=>{
       try{
        const res = await axios.post(loginUri, user)
        setCurrentUser(res.data)
       }catch(err){
        if(err.response.status === 400){
          return  toast({title: 'Амжилтгүй',description: err.response.data ,status: 'error',duration: 3000,isClosable: true,})
        }
           console.log(err)
       }
        // Cookies.set("token", res.data, { expires: 1 });
    }

    const logout = async(user) => {
        await axios.post(logoutUri, user)
        setCurrentUser(null)
    };

    useEffect(()=>{
        localStorage.setItem("AfgHJSHGDAFAgfgaf", JSON.stringify(currentUser));
    }, [currentUser]);

    return(
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}