
// const mainUrl = "http://localhost:5000";

const mainUrl = "https://arigun-backend-new.vercel.app";

export const loginUri = mainUrl + "/auth/login";
export const registerUri = mainUrl + "/auth/register";
export const logoutUri = mainUrl + "/auth/logout";
export const forgetUri = mainUrl + "/auth/forget";
export const resetPassUri = mainUrl + "/auth/resetpass";

export const addtrack = mainUrl + "/track/add";
export const userdatas = mainUrl + "/users/";
export const userupdate = mainUrl + "/users/";
export const userTracks = mainUrl + "/track/";
export const userAddress = mainUrl + "/users/address/";
export const userdatasirsen = mainUrl + "/track/user/irsen/";
export const searchtrack = mainUrl + "/users/track/search";

export const uerdelivery = mainUrl + "/track/user/delivery/";

export const delivery = mainUrl + "/track/delivery/";
export const deletetrack = mainUrl + "/track/";
export const deliveryNumber = mainUrl + "/users/delivery/";
export const ezenguiget = mainUrl + "/track/search/ezenguiuser";

export const ttracks = mainUrl + "/users/all";
export const ezenguibaraaadd = mainUrl + "/users/ezengui/track/baraas"

//others

export const allalert = mainUrl + "/others/alert/all";
export const addressall = mainUrl + "/others/address/all";
export const searchtracknote = mainUrl + "/track/search/note";



export const useraddawsan = mainUrl + "/baraa/awsan";

//new uris
export const userTracksuri = mainUrl + "/baraa/user/tracks";
export const useriduri = mainUrl + "/baraa/user/id";
export const delivremoveuri = mainUrl + "/baraa/delivRemove";