import React, { useEffect, useState } from 'react'
import {FcSearch} from 'react-icons/fc'
import { Input, IconButton, Spinner  } from '@chakra-ui/react'
import {SearchIcon} from "@chakra-ui/icons"
import Ezengui from '../Card/ezengui'
import axios from "axios"
import { ezenguiget } from '../../uri'
import {Link} from 'react-router-dom'

const Header = () => {
  const[tracks, setTracks] = useState([]);
  const[search, setSearch] = useState();
  const [load, setLoad] = useState(false);
  const[empty, setEmpty] = useState(false);

  const fetchData = async ()=>{
    setLoad(true)
    try{
      const res= await axios.post(ezenguiget, {track: search})
      setTracks(res.data)
      setEmpty(true)
      setLoad(false)
    }catch(err){
      setLoad(false)
      console.log(err)
    }
  };
  return (
    <div className='mt-10'>
        <div className='grid xl:grid-cols-2'>
           <div className='grid xl:col-span-1 items-center'>
            <div className='font-Roboto mx-4'>
                <div className='xs:mr-0 md:mr-40'>
                    <div className='font-extrabold text-2xl uppercase'>Хэрэглээний эрх чөлөөг тань</div>
                    <div className='font-extrabold text-4xl uppercase'>бид түгээнэ</div>
                       <p className='mt-8 font-bold'>Book air freight faster and more efficiently with real-time pricing, and booking with American Airlines Cargo. 
                        New to American Airlines Cargo? Sign up for a Precise</p>
                    <div>
                   <Link to='/account'>
                   <div className='mt-4 rounded-md text-center font-extrabold text-white bg-blue-600 px-8 py-3 cursor-pointer uppercase'>эхлэх</div>
                   </Link>
                    </div>
                </div>
                <div className='mt-20 font-bold'>
                    <h1 className='text-xl'>Эзэнгүй бараа хайх</h1>
                    <div className='flex items-center mt-2'>
                      <Input onChange={e=> setSearch(e.target.value)} placeholder='Трак кодоо оруулна уу ' className='mr-2'/>  
                      {
                        load?
                        <IconButton aria-label='Search database' icon={<Spinner />} />
                        :
                        <IconButton onClick={fetchData} aria-label='Search database' icon={<SearchIcon />} />
                      }
                    </div>
                    <div>
                      {tracks.map((track, index) => (
                          <Ezengui
                          key={index}
                          track={track}
                          />
                      ))}
                      {
                        tracks.length == 0 && empty? 
                        <div>Ийм трак кодтой бараа байхгүй байна.</div>
                        :""
                      }
                    </div>
                </div>
            </div>
           </div>
           <div className='grid xl:col-span-1 mt-6 xs:hidden md:block'>
            <img src='../../images/home.png'/>
           </div>
        </div>
    </div>
  )
}

export default Header