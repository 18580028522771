import React from 'react'
import Director from '../components/Home/director'
import Header from '../components/Home/header'
import Team from '../components/Home/team'
import Welcome from '../components/Home/welcome'

const Home = () => {
  return (
    <div>
      <div className='xs:mx-4 xl:mx-20'>
        <Header/>
      </div>
      <div className='bg-slate-200'>
        <Welcome/>
      </div>
      <div className='bg-white xs:mx-4 md:mx-16'>
        {/* <Director/> */}
      </div>
      {/* <div className='bg-slate-200 '>
        <Team/>
      </div> */}
    </div>
  )
}

export default Home