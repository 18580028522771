import React from 'react';
import moment from 'moment';

const Ezengui = ({ track }) => {
  return (
    <div>
      <div className='flex items-center bg-slate-200 py-2 mt-2 justify-between px-4 rounded-sm'>
        <h1 className='hidden md:block'>Таны бараа</h1>
        <div className='mx-2'>{moment.utc(track.date).format("L")}</div> {/* Use moment.utc() */}
        <div>{track.pay} ₮</div>
        <div className='text-center'>
          <div className='flex items-center px-4 py-1 ml-2 bg-green-600 text-xs rounded-lg text-slate-100'>
            Монголд ирсэн
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ezengui;
