import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast
  } from '@chakra-ui/react'
import { allalert, deletetrack } from '../../uri'
import axios from 'axios'
import {AiTwotoneDelete} from 'react-icons/ai'


const DeleteTrackModal = ({id, callback}) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [load, setLoad] = useState(false);

    const handleCallback = () => callback();

    const deleteData = async ()=>{
        setLoad(true)
        try{
            const res = await axios.delete(deletetrack+id)
            if(res.status === 200){
                handleCallback();
                onClose();
                toast({title: 'Амжилттай',description: "Барааг устгалаа",status: 'success',duration: 3000,isClosable: true,})
            }else{
                toast({title: 'Амжилтгүй',description: "Барааг устгаж чадсангүй ",status: 'error',duration: 3000,isClosable: true,})
            }
            setLoad(false)
        }catch(err){
            setLoad(false)
            console.log(err)
        }
    };

  return (
    <>
        <Button className='ml-2' size='sm' onClick={onOpen} color='red.500'><AiTwotoneDelete size={18}/></Button>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Трак устгах</ModalHeader>
                <ModalCloseButton />
            
                <ModalBody>
                    <h1>Трак кодоо устгахдаа итгэлтэй байна уу !</h1>
                </ModalBody>

            <ModalFooter>
                <Button mr={3} onClick={onClose}>
                    Болих
                </Button>
                {
                    load?
                    <Button colorScheme='red' mr={3} onClick={onClose}>
                        Устгаж байна...
                    </Button>
                    :
                    <Button colorScheme='red' mr={3} onClick={deleteData}>
                        Устгах
                    </Button>
                }
            </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default DeleteTrackModal