import { Button, Input, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { userupdate } from '../../../uri';

const PersonalComp = ({user, callback}) => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [load, setLoad] = useState(false)
  const toast = useToast();
  

  useEffect(() => {
    setName(user.tabaoName);
    setPhone(user.phoneNo);
    setEmail(user.email);
  },[user]);

  const handleCallback = () => callback()

  const Submit = async () => {
    setLoad(true)
    try{
      const res= await axios.post(userupdate + user.id, {tabaoName: name, phoneNo: phone, email: email})
      setLoad(false)
      handleCallback();
      toast({title: 'Мэдээлэл шинчиллээ',description: "Аригун карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
    }catch(err){
      console.log(err)
      setLoad(false)
    }
  };

  return (
    <div>
      <div className='flex w-full gap-2'>
        <div className='w-full'>
          <p>Хаягийн нэр</p>
          <Input className='mt-2' placeholder='' value={name} onChange={(e)=> setName(e.target.value)} />
        </div>
        <div className='w-full'>
          <p className='w-full'>Утасны дугаар</p>
          <Input className='mt-2' placeholder='' value={phone} onChange={(e)=> setPhone(e.target.value)} />
        </div>
      </div>
      <div className='flex w-full gap-2 mt-2'>
        <div className='w-full'>
          <p>Имейл хаяг</p>
          <Input className='mt-2' placeholder='' value={email} onChange={(e)=> setEmail(e.target.value)} />
        </div>
      </div>
      <div className='flex justify-end mt-2'>
        {
          load?
          <Button className='shadow-xl animate-bounce'>Хадгалж байна...</Button>
          :
          <Button className='shadow-xl' onClick={Submit}>Хадгалах</Button>
        }
      </div>
    </div>
  )

}

export default PersonalComp