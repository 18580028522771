// import React from 'react'
// import moment from 'moment'

// const DateFormat = ({ dateString }) => {
//     const date = moment.utc(dateString);
//     const formattedDate = date.format("L LTS");
//     return formattedDate;
// }

// export default DateFormat

import React from 'react'
import moment from 'moment'

const DateFormat = ({dateString}) => {
    const date = new Date(dateString);
    const formatedDate = moment(date).format("L");
    return formatedDate;
}

export default DateFormat